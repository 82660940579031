import React, { useState } from 'react';
import { Masonry, MasonryProps } from '@mui/lab';
import { AdvantageItem } from './AdvantageItem';
import { AdvantagesExpandButton } from './AdvantagesExpandButton';

interface AdvantageSectionContentProps {
  items: { id: string; title: string; description: string }[];
  spacing: MasonryProps['spacing'];
  showExpandAdvantagesButton?: boolean;
}

export default (props: AdvantageSectionContentProps) => {
  const [expandedAdvantages, setExpandedAdvantages] = useState<string[]>([]);

  const setAllAdvantagesExpanded = () => {
    setExpandedAdvantages(props.items.map((item) => item.id));
  };

  const handleAdvantageChange = (id: string, isExpanded: boolean) => {
    const newExpandedAdvantages = isExpanded ? [...expandedAdvantages, id] : expandedAdvantages.filter((i) => i !== id);

    setExpandedAdvantages(newExpandedAdvantages);
  };

  return (
    <>
      {/* Zmieniamy czas trawania transition gdyż samo zmienianie wysokości uruchamia animacje, 
      która miesza w wyliczaniu wyokości i dodaje dodatkowe kolumny na ułamek sekundy */}
      <Masonry columns={{ xs: 1, sm: 2 }} spacing={props.spacing} sx={{ transitionDuration: '0s !important' }}>
        {props.items.map((item) => (
          <AdvantageItem
            key={item.id}
            description={item.description}
            title={item.title}
            isExpanded={expandedAdvantages.includes(item.id)}
            onChange={(isExpanded) => handleAdvantageChange(item.id, isExpanded)}
          />
        ))}
      </Masonry>
      {props.showExpandAdvantagesButton && <AdvantagesExpandButton onClick={setAllAdvantagesExpanded} />}
    </>
  );
};

import { Box, Button } from '@mui/material';
import React from 'react';

interface AdvantageExpandButtonProps {
  onClick: () => void;
}

export const AdvantagesExpandButton: React.FC<AdvantageExpandButtonProps> = ({ onClick }) => {
  return (
    <Box mt={5} display='flex' justifyContent='center'>
      <Button sx={{ minWidth: '60%' }} onClick={onClick}>
        Rozwiń wszystkie korzyści
      </Button>
    </Box>
  );
};
